import React from 'react';
import { Button} from 'antd';
import { StyledModal } from './style';

const InvoiceDownloadModal = ({
    visible,
    lang,
    setIsModalNotification,
}) => {
    const closeModal = () => {
        setIsModalNotification(false);
    };

    return (
        <StyledModal
            closable={false}
            visible={visible}
            title={lang('employee:title.invoiceDownloadFailed')}
            footer={[
                <Button key="back" onClick={closeModal}>
                    Ok
                </Button>,
            ]}
        >
            <p>{lang('employee:text.invoiceDownloadFailed')}</p>
        </StyledModal>
    );
};

export default InvoiceDownloadModal;
