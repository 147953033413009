import React from "react";
import { Modal } from "antd";
import { Section } from "./style";
import { ExclamationCircleOutlined } from '@ant-design/icons';

const MaxPicEmailModal = ({
    visible,
    width,
    lang,
    onClose
  }) => {

    return (
      <Modal
        visible={visible}
        centered
        width={width}
        onCancel={onClose}
        footer={null}
      >
        <Section>
          <div className="modal-wrapper">
            <figure className="modal-img">
              <ExclamationCircleOutlined style={{fontSize: '48px', color: '#ffd591'}} />
            </figure>
            <h4>{lang("setting:text.maxPicEmail")}</h4>
          </div>
        </Section>
      </Modal>
    );
  }

  export default MaxPicEmailModal;