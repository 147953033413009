import React, { useState } from "react";
import { Modal, Button, Space, Spin } from "antd";
import { FileOutlined } from "@ant-design/icons";
import Dropzone from "react-dropzone";
import { Section } from "./style";
import upload from "../../../repository/Upload";
import discrepancy from "../../../repository/Discrepancy";
import history from "../../../routers/history";
import { getShortMonth } from "../../../library/dateTimeFormatter";
import fileImg from "../../../assets/images/file-plus.svg";

const DiscrepancyBulkModal = ({
    visible,
    width,
    lang,
    onClose
  }) => {
    const [files, setFiles] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [alert, setAlert] = useState(false);
    const [errors, setErrors] = useState("");
    const [success, setSuccess] = useState("");
    const flag = window.localStorage.getItem("language") || "en";
    const vnd = JSON.parse(window.localStorage.getItem("vnd"));

    function handleFiles(files) {      
      setAlert(false);
      setFiles(files);
    }

    function handleCancel() {
      setFiles([]);
      setAlert(false);
      onClose();
    }

    function handleReset() {
      setFiles([]);
      setAlert(false);
    }
    
    function handleUpload() {
      setErrors("");
      setSuccess("");
      files.forEach(file => {
        handleBulkDiscrepancy(file, vnd.vid, vnd.email);
      });
    }

    function handleBulkDiscrepancy(targetFile, id, email) {
      setUploading(true);
      let fileReader = new FileReader();
      fileReader.onloadend = () => {
        let params = {
          base64: [fileReader.result],
          email: email
        };
        upload
          .uploadFile({ params : params })
          .then(res => {
            if (res.code === 200) {
              let data = {
                vendor_id: id,
                file_url: res.data[0]
              }
              uploadDiscrepancy(data);
            } else {
              setErrors(res.message[flag]);
              setAlert(true);
              setUploading(false);
            }
          })
      };
      fileReader.readAsDataURL(targetFile);
    }

    function uploadDiscrepancy(data) {
      discrepancy
        .bulkUpload({ params: data })
        .then(res => {
          if (res.status === 200) {
            setSuccess(
              "Successfully Uploaded! Total data: "
              + res.meta.csv_rows 
              + ". Total store: "
              + res.meta.inserted_rows
            );
            setAlert(true);
            setFiles([]);
            setTimeout(() => {
              let month = getShortMonth(new Date().getMonth());
              let year = new Date().getFullYear();
              history.push("/list/discrepancy/detail/" + month + year);
            }, 2000);
          } else {
            setErrors(res.message[flag]);
            setAlert(true);
          }
          setUploading(false);
        })
    }

    return (
      <Modal
        visible={visible}
        width={width}
        onCancel={onClose}
        title={lang("employee:title.bulkDiscrepancyModal")}
        footer={[
          <Button
            key="cancel"
            onClick={handleCancel}
          >
            {lang("employee:button.cancel")}
          </Button>,
          <Button 
            key="upload"
            type="primary"
            disabled={uploading}
            onClick={handleUpload}
          >
            {lang("employee:button.uploadList")}
          </Button>
        ]}
      >
        <Section>
          <div className="modal-wrapper">
            <Dropzone onDrop={handleFiles}>
              {({getRootProps, getInputProps}) => (
                <section className="dropzone text-center">
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    {files.length > 0 ? (
                      <div className="dropzone-preview">
                        {files.map(file => (
                          <p key={file.name}><FileOutlined /> {file.name}</p>
                        ))}
                        {uploading && (
                          <Space>
                            <Spin size="large" />
                          </Space>
                        )}
                        {errors && alert && (
                          <div className="text-center">
                            <p className="error-message">
                              {errors}
                            </p>
                            <Button type="default" onClick={() => handleReset}>
                              Reset
                            </Button>
                          </div>
                        )}
                      </div>
                    ) : (
                      <div>
                        {success && alert ? (
                          <div className="text-center">
                            <p className="success-message">
                              {success}
                            </p>
                          </div>
                        ) : (
                          <div>
                            <figure className="dropzone-img">
                              <img src={fileImg} alt="" />
                            </figure>
                            <p>{lang("employee:text.dragHere")}</p>
                            <Button key="choose" type="default">
                              {lang("employee:button.chooseFile")}
                            </Button>
                          </div>
                        )}
                        
                      </div>
                    )}
                  </div>
                </section>
              )}
            </Dropzone>
          </div>
        </Section>
      </Modal>
    );
  }

  export default DiscrepancyBulkModal;