import React, { useState } from "react";
import {
  Modal,
  Button,
  Input,
  InputNumber
} from "antd";
import { Section } from "./style";
import {
  currencyNoPrefix
} from "../../../library/currencyFormatter";
import discrepancy from "../../../repository/Discrepancy";
import { getShortMonth } from "../../../library/dateTimeFormatter";
import history from "../../../routers/history";

const DiscrepancyManualModal = ({
    visible,
    width,
    lang,
    onClose
  }) => {
    const [prevTotal, setPrevTotal] = useState(0);
    const [alert, setAlert] = useState("");
    const [success, setSuccess] = useState("");
    const [discrepancies, setDiscrepancies] = useState([
      {
        employee_name: "",
        salary_given: 0,
        bank_account_number: "",
        disbursed_loan: 0,
        discrepancy_amount: 0
      }
    ]);
    const dataVendor = JSON.parse(
      window.localStorage.getItem("vnd")
    );
    const flag = window.localStorage.getItem("language") || "en";

    const handleAdd = () => {
      let rows = discrepancies;
      rows.push({
        employee_name: "",
        salary_given: "",
        bank_account_number: "",
        disbursed_loan: "",
        discrepancy_amount: ""
      });
      setPrevTotal(prevTotal+1);
      setDiscrepancies(rows);
    }

    const handleRemove = (idx) => {
      let rows = [...discrepancies];
      rows.splice(idx,1);
      setDiscrepancies(rows);
      setPrevTotal(prevTotal-1);
    }

    const handleChange = (idx, {target}) => {
      let items = [...discrepancies];
      items[idx][target.name] = target.value;
      setDiscrepancies(items)
    }

    const handleNumberChange = (idx, name, value) => {
      let item = [...discrepancies];
      item[idx][name] = value;
      if (name === "disbursed_loan") {
        item[idx]["discrepancy_amount"] = value - item[idx]["salary_given"]
      }
      setDiscrepancies(item);
    }

    const handleSave = () => {
      setAlert("");
      let checkData = filterData(discrepancies, "discrepancy_amount");
      if(checkData.length !== 0) {
        setAlert(lang("employee:text.cannotSaveRemoveFirst"));
      } else {
        discrepancy
          .createDiscrepancy({
            id: dataVendor.vid,
            params: discrepancies
          })
          .then(res => {
            if (res && res.status === 200) {
              setSuccess(
                "Successfully stored! Total data: "
                + res.meta.total_data 
                + ". Total stored: "
                + res.meta.inserted_data
              );
              setTimeout(() => {
                let month = getShortMonth(new Date().getMonth());
                let year = new Date().getFullYear();
                history.push("/list/discrepancy/detail/" + month + year);
              }, 2000);
              
            } else {
              setAlert(res.message[flag]);
            }
          });
      }
    }

    const filterData = (arr, searchKey) => {
      return arr.filter(
        obj => (obj[searchKey] === "" || obj[searchKey] === 0)
      );
    }

    return (
      <Modal
        visible={visible}
        width={width}
        onCancel={onClose}
        footer={null}
      >
        <Section>
          <div className="modal-wrapper">            
            <div className="top-button">
              {alert && (
                <span
                  style={{
                    float: "left",
                    color: "#C53741"
                  }}
                >
                  {lang("employee:text.cannotSaveRemoveFirst")}
                </span>
              )}
              {success && (
                <span
                  style={{
                    float: "left",
                    color: "#0ba22b"
                  }}
                >
                  {success}
                </span>
              )}
              <Button type="success" onClick={handleAdd}>
                {lang("employee:button.add")}
              </Button>
              <Button type="primary" onClick={handleSave}>
                {lang("employee:button.save")}
              </Button>
            </div>

            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>No.</th>
                  <th>{lang("employee:label.employeeName")}</th>
                  <th>{lang("employee:label.salaryGiven")}</th>
                  <th>{lang("employee:label.bankAccountNumber")}</th>
                  <th>{lang("employee:label.salaryAdvDisbursed")}</th>
                  <th>{lang("employee:label.discrepancy")}</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                { (prevTotal !== discrepancies.length) ? (
                  discrepancies.map((item, idx) => (
                    <tr key={idx+1}>
                      <td>{idx+1}.</td>
                      <td>
                        <Input
                          name="employee_name"
                          value={item.employee_name}
                          onChange={e => handleChange(idx, e)}
                        />
                      </td>
                      <td>
                        <InputNumber
                          name="salary_given"
                          value={item.salary_given}
                          className="w-100"
                          formatter={value => `${currencyNoPrefix(value)}`}
                          onChange={
                            e => handleNumberChange(idx, 'salary_given', e)
                          }
                        />
                      </td>
                      <td>
                        <Input
                          name="bank_account_number"
                          value={item.accountNumber}
                          onChange={e => handleChange(idx, e)}
                        />
                      </td>
                      <td>
                        <InputNumber
                          name="disbursed_loan"
                          value={item.disbursed_loan}
                          className="w-100"
                          formatter={value => `${currencyNoPrefix(value)}`}
                          onChange={
                            e => handleNumberChange(idx, 'disbursed_loan', e)
                          }
                        />
                      </td>
                      <td>
                        <InputNumber
                          name="discrepancy_amount"
                          value={item.discrepancy_amount}
                          formatter={value => `${currencyNoPrefix(value)}`}
                        />
                      </td>
                      <td>
                        {idx !== 0 && (
                          <span 
                            style={{
                              color: "#C53741",
                              cursor: "pointer"
                            }}
                            onClick={() => handleRemove(idx)}
                          >
                            {lang("employee:button.remove")}
                          </span>
                        )}
                      </td>
                    </tr>
                  ))
                ) : null }
              </tbody>
            </table>
          </div>
        </Section>
      </Modal>
    );
  }

  export default DiscrepancyManualModal;