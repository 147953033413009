import React, { useState, useEffect } from "react";
import {
    Form,
    Input,
    InputNumber,
    DatePicker,
    Button,
    Card,
    Row,
    Col,
    message,
    Select,
    Modal,
} from "antd";
import { LeftOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import moment from "moment";
import history from "../../routers/history";
import employee from "../../repository/Employee";
import partner from "../../repository/Partner";
import { dateISOtoStringFormat } from "../../library/dateTimeFormatter";
import { currencyNoPrefix } from "../../library/currencyFormatter";
// import ClevertapReact from "../../library/clevertap";
import clevertap from "clevertap-web-sdk";
import { Section } from "./style";
import LoanHistory from "./loanHistory";
import PATH_URL from "../../routers/path";
import { statusEmployeeOption, loanAccessOption } from "./dataOption";
import loan from "../../repository/Loan";
import ResignedEmployeeModal from "../../components/Modal/ResignedEmployeeModal";

function EmployeeDetailContent(props) {
    const { t } = useTranslation();
    // const lang = window.localStorage.getItem("language") || "en";
    const dataVendor = JSON.parse(window.localStorage.getItem("vnd"));
    const id = props.match.params.employeeId;
    const [name, setName] = useState("");
    const [number, setNumber] = useState("");
    const [dob, setDob] = useState("");
    const [defaultDob, setDefaultDob] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [nik, setNik] = useState("");
    const [firstDow, setFirstDow] = useState("");
    const [defaultDow, setDefaultDow] = useState("");
    const [defaultSalary, setDefaultSalary] = useState(0);
    const [salary, setSalary] = useState(0);
    const [department, setDepartment] = useState("");
    const [bankName, setBankName] = useState("");
    const [accountNumber, setAccountNumber] = useState("");
    const [accountStatus, setAccountStatus] = useState("");
    const [employmentType, setEmploymentType] = useState("");
    const [endOfContract, setEndOfContract] = useState("");
    const [defaultEndOfContract, setDefaultEndOfContract] = useState("");
    const [position, setPosition] = useState("");
    const [statusDisabled, setStatusDisabled] = useState(true);
    const [loadingUpdate, setLoadingUpdate] = useState(false);
    const [eocDisable, setEocDisable] = useState(true);
    const [isDisabled, setIsDisabled] = useState(true);
    // const [isFrozen, setIsFrozen] = useState(false);
    const [isEdited, setEdited] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isModalActiveLoan, setModalActiveLoan] = useState(false);
    const [isModalRequestResignSuccess, setModalRequestResignSuccess] =
        useState(false);
    const [isUpdated, setIsUpdated] = useState(null);
    const { Option } = Select;
    const [isModalActivatedVisible, setIsModalActivatedVisible] =
        useState(false);
    const [loanOption, setLoanOption] = useState("");

    const [outstandingAmount, setOutstandingAmount] = useState(0);

    function handleNameChange({ target }) {
        setName(target.value);
    }
    function handleNumberChange({ target }) {
        setNumber(target.value);
    }
    function handleNikChange({ target }) {
        setNik(target.value);
    }
    function handlePhoneChange({ target }) {
        setPhoneNumber(target.value);
    }
    function handleDepartmentChange({ target }) {
        setDepartment(target.value);
    }
    function handleBankNameChange({ target }) {
        setBankName(target.value);
    }
    function handleAccountNumber({ target }) {
        setAccountNumber(target.value);
    }
    function handleDobChange(date, dateString) {
        setDob(dateString);
    }
    function handleDowChange(date, dateString) {
        setFirstDow(dateString);
    }
    function handleSalaryChange(value) {
        setSalary(value);
    }
    function handleEmploymentType(value) {
        setEocDisable(false);
        if (value === "permanent") {
            setEocDisable(true);
        }

        setEmploymentType(value);
    }
    function handleLoanOption(value) {
      setLoanOption(value);
    }  
    function handleEndOfContract(date, dateString) {
        setEndOfContract(dateString);
    }
    function handlePosition({ target }) {
        setPosition(target.value);
    }
    // function handleFreeze() {
    //   employee
    //     .putFreezeEmployee({
    //       id: id,
    //       status: !isFrozen
    //     })
    //     .then(res => {
    //       if (res.status === 200) {
    //         message.success("success freeze/unfreeze");
    //         setIsFrozen(!isFrozen);
    //       } else {
    //         message.error(res.message[lang])
    //       }
    //     });
    // }
    function handleEdit() {
        setIsDisabled(false);
    }
    function handleUpdate() {
        let params = {
            account_number: accountNumber,
            salary: salary,
            employee_number: number,
            employee_name: name,
            department: department,
            bank_name: bankName,
            nik: nik,
            dob: dob,
            phone_number: phoneNumber,
            employeed_since: firstDow,
            employment_type: employmentType,
            position: position,
            end_of_contract: eocDisable ? "" : endOfContract,
            loan_access: loanOption,
        };

        setLoadingUpdate(true);

        employee
            .putEmployeeDetail({
                id: id,
                params: params,
            })
            .then((res) => {
                if (res.status === 200) {
                    let currentDate = new Date();
                    let eventData = {
                        "Employee Name": name,
                        "Employee Phone Number": phoneNumber,
                        "Employee Department": department,
                        "Employee Status": accountStatus,
                        Source: "List Menu",
                        Category: "Existing List",
                        "Partner Name": dataVendor.name,
                        Date: currentDate,
                        "Formatted Date":
                            currentDate.getDate() +
                            "/" +
                            (currentDate.getMonth() + 1) +
                            "/" +
                            currentDate.getFullYear(),
                    };
                    clevertap.onUserLogin.push({
                        Site: {
                            Name: dataVendor.name,
                            Email: dataVendor.email,
                        },
                        ProfileData: {
                            "Partner Name": dataVendor.name,
                            Email: dataVendor.email,
                            AccountType: "partner",
                        },
                    });
                    clevertap.event.push("Update Employee KoinGaji", eventData);

                    message.success(t("employee:text.successUpdate"));
                    updateEmployeeMonthly(id, "true");
                    setIsDisabled(true);
                } else {
                    message.error(res.errors[0].moreInfo);
                }
                setIsUpdated(res.status);
                setLoadingUpdate(false);
            });
    }

    function handleUpdateStatus() {
        let params = {
            status: accountStatus,
        };

        setLoadingUpdate(true);

        employee
            .postEmployeeStatus({
                id: id,
                params: params,
            })
            .then((res) => {
                if (res.status === 200) {
                    message.success(t("employee:text.successUpdate"));
                    updateEmployeeMonthly(id, "true");
                    window.location.reload();
                } else {
                    message.error(res.errors[0].userMessage);
                }
                setLoadingUpdate(false);
            });
    }

    function updateEmployeeMonthly(id, status) {
        partner
            .putMonthlyUpdateEmployee({
                id: id,
                status: status,
            })
            .then((res) => {
                if (res.status === 200) {
                    let vendor = dataVendor;
                    vendor.monthly_update = "true";
                    window.localStorage.setItem("vnd", JSON.stringify(vendor));
                    window.localStorage.setItem(
                        "ep_update",
                        JSON.stringify({
                            vendor: dataVendor.vid,
                        })
                    );
                }
            });
    }

    function editStatus(value) {
        setEdited(!isEdited);
    }

    function handleStatus(value) {
        if (value !== accountStatus) {
            if (value === "activated") {
                setAccountStatus(value);
                setIsModalActivatedVisible(true);
            } else {
                setAccountStatus(value);
                setIsModalVisible(true);
            }
        }
    }

    function handleResignStatus() {
        loan.getLoanHistory({
            id: id,
            page: 1,
            limit: 10,
            filter: "",
        }).then((res) => {
            if (res.status === 200 && res.data) {
                if (res.data.length > 0) {
                    const statusActive = ["on_going", "npl"];

                    let activeLoan = res.data.filter((el) =>
                        statusActive.includes(el.loan_status)
                    );
                    if (activeLoan.length > 0) {
                        employee
                            .getEmployeeOutstanding({
                                employee_id: id,
                            })
                            .then((res) => {
                                if (res.status === 200) {
                                    setOutstandingAmount(
                                        res.data.outstanding_amount
                                    );
                                    setModalActiveLoan(true);
                                }
                            });
                    } else {
                        handleUpdateStatus();
                    }
                }
            } else {
                handleUpdateStatus();
            }
        });
    }

    const handleOk = () => {
        setIsModalVisible(false);
        setIsModalActivatedVisible(false);
        if (accountStatus === "resigned") {
            handleResignStatus();
        } else {
            handleUpdateStatus();
        }
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setIsModalActivatedVisible(false);
    };

    const handleRequestResignSuccess = () => {
        setModalRequestResignSuccess(false);
        window.location.reload();
    };

    useEffect(() => {
        let unmountedEmployee = false;
        employee
            .getEmployeeDetail({
                id: id,
            })
            .then((res) => {
                if (!unmountedEmployee && res.status === 200 && res.data) {
                    setName(res.data.employee_name);
                    setNumber(res.data.employee_number);
                    setDob(dateISOtoStringFormat(res.data.dob));
                    setDefaultDob(res.data.dob);
                    setFirstDow(
                        dateISOtoStringFormat(res.data.employeed_since)
                    );
                    setDefaultDow(res.data.employeed_since);
                    setNik(res.data.nik);
                    setPhoneNumber(res.data.phone_number);
                    setSalary(res.data.salary);
                    setDefaultSalary(res.data.salary);
                    setDepartment(res.data.department);
                    setBankName(res.data.bank_name);
                    setAccountNumber(res.data.account_number);
                    setAccountStatus(
                        res.data.properties.employee_status
                            ? res.data.properties.employee_status
                            : "pending"
                    );
                    setEmploymentType(res.data.employment_type);
                    setEndOfContract(
                        dateISOtoStringFormat(res.data.end_of_contract)
                    );
                    setDefaultEndOfContract(
                        res.data.end_of_contract
                            ? res.data.end_of_contract
                            : dateISOtoStringFormat(moment().add(1, "months"))
                    );
                    setPosition(res.data.position);
                    setLoanOption(res.data.loan_access);
                    // setIsFrozen(res.data.is_frozen);

                    // set end of contract disable
                    if (res.data.employment_type.toLowerCase() === "contract")
                        setEocDisable(false);

                    // disabled edit
                    let today = new Date();
                    let updateStatus = new Date(
                        res.data.properties.last_update_status
                    );
                    
                    if (res.data.properties.employee_status) {
                        if (
                            res.data.properties.employee_status === "activated"
                        ) {
                            setStatusDisabled(false);
                        } else if (
                            res.data.properties.employee_status ===
                                "unreachable" &&
                            moment(today).diff(
                                res.data.properties.last_update_status,
                                "days"
                            ) >= 30
                        ) {
                            setStatusDisabled(false);
                        } else if (
                            res.data.properties.employee_status ===
                                "deactivated" &&
                            moment
                                .duration(
                                    moment(updateStatus).diff(
                                        moment().endOf("day")
                                    )
                                )
                                .asDays() < -1
                        ) {
                            setStatusDisabled(false);
                        } else {
                            setStatusDisabled(true);
                        }
                    } else {
                        setStatusDisabled(true);
                    }
                }
                unmountedEmployee = false;
            });

        return () => {
            unmountedEmployee = true;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isUpdated]);

    return (
        <Section>
            <div className="kwEmployee">
                <h2>
                    <span onClick={() => history.push(PATH_URL.EMPLOYEE_LIST)}>
                        <LeftOutlined />
                    </span>
                    {t("employee:title.backToEmployee")}
                </h2>
                <Card>
                    <Row>
                        <Col xs={24} md={8}>
                            <h3>{t("employee:title.employeeDetails")}</h3>
                            <div className="kwEmployee__top-button">
                                <Button
                                    type="primary"
                                    onClick={handleUpdate}
                                    loading={loadingUpdate}
                                    disabled={isDisabled || loadingUpdate}
                                >
                                    {t("setting:button.save")}
                                </Button>
                                <Button
                                    type="primary"
                                    onClick={handleEdit}
                                    disabled={!isDisabled || loadingUpdate}
                                    loading={loadingUpdate}
                                >
                                    {t("employee:button.editEmployee")}
                                </Button>
                            </div>
                            <Form>
                                <Form.Item>
                                    <span className="input-label">
                                        {t("employee:label.employeeName")}
                                    </span>
                                    <Input
                                        type="text"
                                        value={name}
                                        id="name"
                                        onChange={handleNameChange}
                                        disabled={isDisabled}
                                    />
                                </Form.Item>
                                <Form.Item>
                                    <span className="input-label">
                                        {t("employee:label.employeeNumber")}
                                    </span>
                                    <Input
                                        type="text"
                                        id="employeeNumber"
                                        value={number}
                                        onChange={handleNumberChange}
                                        disabled={isDisabled}
                                    />
                                </Form.Item>
                                <Form.Item>
                                    <span className="input-label">
                                        {t("employee:label.birthDate")}
                                    </span>
                                    {defaultDob && (
                                        <DatePicker
                                            className="w-100"
                                            id="birthDate"
                                            allowClear={false}
                                            defaultValue={moment(defaultDob)}
                                            onChange={handleDobChange}
                                            disabled={isDisabled}
                                        />
                                    )}
                                </Form.Item>
                                <Form.Item>
                                    <span className="input-label">
                                        {t("employee:label.phoneNumber")}
                                    </span>
                                    <Input
                                        type="text"
                                        id="phoneNumber"
                                        value={phoneNumber}
                                        onChange={handlePhoneChange}
                                        disabled={isDisabled}
                                    />
                                </Form.Item>
                                <Form.Item>
                                    <span className="input-label">
                                        {t("employee:label.ktpNumber")}
                                    </span>
                                    <Input
                                        type="text"
                                        id="nik"
                                        value={nik}
                                        onChange={handleNikChange}
                                        disabled={isDisabled}
                                    />
                                </Form.Item>
                                <Form.Item>
                                    <span className="input-label">
                                        {t("employee:label.firstWork")}
                                    </span>
                                    {defaultDow && (
                                        <DatePicker
                                            className="w-100"
                                            id="firstWork"
                                            allowClear={false}
                                            defaultValue={moment(defaultDow)}
                                            onChange={handleDowChange}
                                            disabled={isDisabled}
                                        />
                                    )}
                                </Form.Item>
                                <Form.Item>
                                    <span className="input-label">
                                        {t("employee:label.currentSalary")}
                                    </span>
                                    {defaultSalary && (
                                        <InputNumber
                                            type="text"
                                            className="w-100"
                                            id="salary"
                                            defaultValue={defaultSalary}
                                            value={salary}
                                            formatter={(value) =>
                                                `${currencyNoPrefix(value)}`
                                            }
                                            onChange={handleSalaryChange}
                                            disabled={isDisabled}
                                        />
                                    )}
                                </Form.Item>
                                <Form.Item>
                                    <span className="input-label">
                                        {t("employee:label.department")}
                                    </span>
                                    <Input
                                        type="text"
                                        id="department"
                                        value={department}
                                        onChange={handleDepartmentChange}
                                        disabled={isDisabled}
                                    />
                                </Form.Item>
                                <Form.Item>
                                    <span className="input-label">
                                        {t("employee:label.bankName")}
                                    </span>
                                    <Input
                                        type="text"
                                        id="bankName"
                                        value={bankName}
                                        onChange={handleBankNameChange}
                                        disabled={isDisabled}
                                    />
                                </Form.Item>
                                <Form.Item>
                                    <span className="input-label">
                                        {t("employee:label.bankAccountNumber")}
                                    </span>
                                    <Input
                                        type="text"
                                        id="accountNumber"
                                        value={accountNumber}
                                        onChange={handleAccountNumber}
                                        disabled={isDisabled}
                                    />
                                </Form.Item>
                                <Form.Item>
                                    <span className="input-label">
                                        {t("employee:label.status")}
                                    </span>
                                    <Select
                                        defaultValue={employmentType}
                                        value={employmentType}
                                        onChange={handleEmploymentType}
                                        id="employmentType"
                                        disabled={isDisabled}
                                    >
                                        {statusEmployeeOption.map((item) => (
                                            <Select.Option
                                                value={item.value}
                                                key={item.value}
                                            >
                                                {item.label}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                <Form.Item>
                                    <span className="input-label">
                                        {t("employee:label.endOfContract")}
                                    </span>
                                    {defaultEndOfContract && (
                                        <DatePicker
                                            className="w-100"
                                            id="endOfContract"
                                            allowClear={true}
                                            onChange={handleEndOfContract}
                                            defaultValue={moment(
                                                defaultEndOfContract
                                            )}
                                            disabled={isDisabled || eocDisable}
                                            disabledDate={(d) =>
                                                !d ||
                                                d.isBefore(
                                                    moment().add(1, "months")
                                                )
                                            }
                                        />
                                    )}
                                </Form.Item>
                                <Form.Item>
                                    <span className="input-label">
                                        {t("employee:label.position")}
                                    </span>
                                    <Input
                                        type="text"
                                        id="position"
                                        value={position}
                                        onChange={handlePosition}
                                        disabled={isDisabled}
                                    />
                                </Form.Item>
                                <Form.Item>
                                    <span className="input-label">
                                        {t("employee:label.loanOption")}
                                    </span>
                                    <Select
                                        defaultValue={loanOption}
                                        value={loanOption}
                                        onChange={handleLoanOption}
                                        id="loanOption"
                                        disabled={isDisabled}
                                    >
                                        {loanAccessOption.map((item) => (
                                        <Select.Option value={item.value} key={item.value}>
                                            {item.label}
                                        </Select.Option>
                                        ))}
                                    </Select> 
                                </Form.Item>
                            </Form>
                        </Col>

                        <Col md={{ span: 12, offset: 4 }} xs={24}>
                            <div>
                                <h3
                                    style={{
                                        display: "inline",
                                        marginRight: "10px",
                                        marginLeft: "20%",
                                    }}
                                >
                                    {t("employee:title.employeeStatus")}
                                </h3>
                                <Button
                                    type="primary"
                                    onClick={editStatus}
                                    className="btn-edit-employee"
                                    disabled={statusDisabled}
                                >
                                    {t("employee:button.editEmployeeStatus")}
                                </Button>
                                {!isEdited && (
                                    <p
                                        className={`text-status status-${accountStatus} mt-2`}
                                    >
                                        {accountStatus}
                                    </p>
                                )}
                                {isEdited && (
                                    <div className="ml-20 mt-4">
                                        <p
                                            style={{
                                                textTransform: "uppercase",
                                            }}
                                        >
                                            {t("employee:label.status")}
                                        </p>
                                        <Select
                                            defaultValue={accountStatus}
                                            style={{ width: "100%" }}
                                            onChange={handleStatus}
                                        >
                                            <Option value="pending" disabled>
                                                {t("employee:label.pending")}
                                            </Option>
                                            <Option value="activated">
                                                {t("employee:label.active")}
                                            </Option>
                                            <Option value="resigned">
                                                {t("employee:label.resigned")}
                                            </Option>
                                            <Option value="unreachable">
                                                {t(
                                                    "employee:label.notReachable"
                                                )}
                                            </Option>
                                            <Option value="frozen">
                                                {t("employee:label.frozen")}
                                            </Option>
                                        </Select>
                                    </div>
                                )}
                                <span className="p-right-aligned">
                                    {accountStatus === "activated" &&
                                        t("employee:text.noteStatusActive")}
                                    {accountStatus === "pending" &&
                                        t("employee:text.noteStatusPending")}
                                    {accountStatus === "resigned" &&
                                        t("employee:text.noteStatusResigned")}
                                    {accountStatus === "unreachable" &&
                                        t(
                                            "employee:text.noteStatusUnreachable"
                                        )}
                                    {accountStatus === "frozen" &&
                                        t("employee:text.noteStatusFrozen")}
                                    {accountStatus === "rejected" &&
                                        t("employee:text.noteStatusRejected")}
                                </span>
                                <Modal
                                    title={
                                        accountStatus === "resigned"
                                            ? t("employee:title.markResigned")
                                            : accountStatus === "unreachable"
                                            ? t(
                                                  "employee:title.markUnreachable"
                                              )
                                            : t("employee:title.markFrozen")
                                    }
                                    visible={isModalVisible}
                                    footer={[
                                        <Button
                                            key="back"
                                            onClick={handleCancel}
                                        >
                                            Cancel
                                        </Button>,
                                        <Button
                                            key="submit"
                                            type="primary"
                                            onClick={handleOk}
                                        >
                                            Continue
                                        </Button>,
                                    ]}
                                >
                                    <p>
                                        {accountStatus === "resigned"
                                            ? t("employee:text.markResigned")
                                            : accountStatus === "unreachable"
                                            ? t("employee:text.markResigned")
                                            : t("employee:text.markFrozen")}
                                    </p>
                                </Modal>

                                <Modal
                                    title={t("employee:title.markActivated")}
                                    visible={isModalActivatedVisible}
                                    footer={[
                                        <Button
                                            key="back"
                                            onClick={handleCancel}
                                        >
                                            Cancel
                                        </Button>,
                                        <Button
                                            key="submit"
                                            type="primary"
                                            onClick={handleOk}
                                        >
                                            Continue
                                        </Button>,
                                    ]}
                                >
                                    <p>{t("employee:text.markActivated")}</p>
                                </Modal>

                                <Modal
                                    closable={false}
                                    visible={isModalRequestResignSuccess}
                                    footer={[
                                        <Button
                                            key="submit"
                                            type="primary"
                                            onClick={handleRequestResignSuccess}
                                        >
                                            Oke
                                        </Button>,
                                    ]}
                                >
                                    <h5 className="text-center">
                                        {t("employee:text.requestReviewed")}
                                    </h5>
                                </Modal>

                                {/* --- add employee modal --- */}
                                <ResignedEmployeeModal
                                    data={{ id, nik, name, outstandingAmount }}
                                    width={1000}
                                    visible={isModalActiveLoan}
                                    showModal={setModalActiveLoan}
                                    showModalSuccess={
                                        setModalRequestResignSuccess
                                    }
                                />

                                {/* { isFrozen && (
                  <div>
                    <span
                      style={{
                        marginRight: '10px',
                        color: '#e15d1c'
                      }}
                    >
                      This employee has been frozen
                    </span>
                    <Button
                      type="primary"
                      onClick={handleFreeze}
                    >
                      {t("employee:button.unfreeze")}
                    </Button>
                  </div>
                )}            
                { !isFrozen && (
                  <Button 
                    type="danger"
                    onClick={handleFreeze}
                  >
                    {t("employee:button.freeze")}
                  </Button>
                )} */}
                            </div>
                            <div className="kwEmployee__right-content pull-right">
                                <LoanHistory t={t} loanId={id} />
                            </div>
                        </Col>
                    </Row>
                </Card>
            </div>
        </Section>
    );
}

export default EmployeeDetailContent;
