import React from "react";
import { Modal, Button, message} from "antd";
import { Section } from "./style";
import employee from "../../../repository/Employee";
import { saveAs } from "file-saver";
import { getFileNameFromUrl } from "../../../library/fileSaverHelper";

const NotificationModal = ({
    visible,
    width,
    lang,
    setIsNotification,
    lastDay
  }) => {    
    const handleCloseNotification = () => {
      setIsNotification(false);
    }

    function getEmployeeTemplate() {
      employee
        .getEmployeeTemplate({})
        .then(res => {
          if (res.status === 200 && res.data) {
            saveAs(res.data.url_download, getFileNameFromUrl(res.data.url_download));
            setIsNotification(false);
          } else {
            message.error(res.message[lang])
          }
        });
    }


    const handleOkDownloadTemplate = () => {
      getEmployeeTemplate();
    };


    let today = new Date().setHours(0,0,0,0);
    let last = new Date(lastDay).setHours(0,0,0,0);
    visible= (visible && (today < last)) ? true : false;
    
    return (
      <Modal 
        width={width}
        title={lang("employee:text.notificationTitle")}
        closable={false}
        visible={visible} 
        footer={[
        <Button key="submit" type="primary" onClick={handleCloseNotification}>
          Oke
        </Button>,
      ]}
      >
      <Section>
        <p>{lang("employee:text.notificationBody")} <b onClick={handleOkDownloadTemplate} style={{cursor:'pointer'}}>download disini.</b></p>
      </Section>
    </Modal>
    );
  }    

  export default NotificationModal;