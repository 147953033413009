import React , { useState }from "react";
import { useTranslation } from "react-i18next";
import {
  Form,
  Input,
  InputNumber,
  DatePicker,
  Button,
  Row,
  Col,
  message,
  Modal
} from "antd";
import { currencyNoPrefix } from "../../../library/currencyFormatter";
import employee from "../../../repository/Employee";
import Dropzone from "react-dropzone";
import upload from "../../../repository/Upload";

const ResignedEmployeeModal = ({
    data,
    visible,
    width,
    showModal,
    showModalSuccess
  }) => {
    const { t } = useTranslation();
    const [isDisable, setIsDisable]= useState(false);
    const [resignLetter, setResignLetter] = useState("");
    const [employeeLastDay, setEmployeeLastDay] = useState("");
    const [employeeLastIncome, setEmployeeLastIncome] = useState("");
    const [paymentDate, setPaymentDate] = useState("");
    const [latestAddress, setLatestAddress] = useState("");
    const [activePhoneNumber, setActivePhoneNumber] = useState("");
    const vnd = JSON.parse(window.localStorage.getItem("vnd"));
    const flag = window.localStorage.getItem("language") || "en";
    const employeeId = data.id;
    const employeeName = data.name;
    const employeeNik = data.nik;
    const outstandingAmount = data.outstandingAmount;

    const [mandatoryResignLetter, setMandatoryResignLetter] = useState("");
    const [mandatoryEmployeeName, setMandatoryEmployeeName] = useState("");
    const [mandatoryEmployeeNik, setMandatoryEmployeeNik] = useState("");
    const [mandatoryEmployeeLastDay, setMandatoryEmployeeLastDay] = useState("");
    const [mandatoryEmployeeLastIncome, setMandatoryEmployeeLastIncome] = useState("");
    const [mandatoryPaymentDate, setMandatoryPaymentDate] = useState("");
    const [mandatoryLatestAddress, setMandatoryLatestAddress] = useState("");
    const [mandatoryActivePhoneNumber, setMandatoryActivePhoneNumber] = useState("");
    const [mandatoryOutstandingAmount, setMandatoryOutstandingAmount] = useState("");
    
    // form handling
    function handleResignLetterChange(file) {
      validateResignLetter(file);
      setResignLetter(file);
    }

    function handleEmployeeLastDayChange(date, dateString){
      validateEmployeeLastDay(dateString);
      setEmployeeLastDay(dateString);
    }
    
    function handleEmployeeLastIncomeChange(value){
      validateEmployeeLastIncome(value);
      setEmployeeLastIncome(value);
    }
    
    function handlePaymentDateChange(date, dateString){
      validatePaymentDate(dateString);
      setPaymentDate(dateString);
    }
    
    function handleActivePhoneNumberChange({ target }){
      if(isNaN(target.value)) return false;
      validateActivePhoneNumber(target.value);
      setActivePhoneNumber(target.value);
    }
    
    function handleLatestAddressChange({ target }){
      validateLatestAddress(target.value);
      setLatestAddress(target.value);
    }

    // form validation
    function validateResignLetter(value) {
      let status = value === "" ? "error" : "";
      setMandatoryResignLetter(status);
    }

    function validateEmployeeName(value) {
      let status = value === "" ? "error" : "";
      setMandatoryEmployeeName(status);
    }

    function validateEmployeeNik(value) {
      let status = value === "" ? "error" : "";
      setMandatoryEmployeeNik(status);
    }

    function validateEmployeeLastDay(value) {
      let status = value === "" ? "error" : "";
      setMandatoryEmployeeLastDay(status);
    }

    function validateEmployeeLastIncome(value) {
      let status = value === "" || value === null || isNaN(value) ? "error" : "";
      setMandatoryEmployeeLastIncome(status);
    }

    function validatePaymentDate(value) {
      let status = value === "" ? "error" : "";
      setMandatoryPaymentDate(status);
    }

    function validateLatestAddress(value) {
      let status = value === "" ? "error" : "";
      setMandatoryLatestAddress(status);
    }

    function validateActivePhoneNumber(value) {
      let status = value === "" ? "error" : "";
      setMandatoryActivePhoneNumber(status);
    }

    function validateOutstandingAmount(value) {
      let status = value === "" ? "error" : "";
      setMandatoryOutstandingAmount(status);
    }

    function handleSaveResignedEmployees(){
      validateResignLetter(resignLetter);
      validateEmployeeName(employeeName);
      validateEmployeeNik(employeeNik);
      validateEmployeeLastDay(employeeLastDay);
      validateEmployeeLastIncome(employeeLastIncome);
      validatePaymentDate(paymentDate);
      validateLatestAddress(latestAddress);
      validateActivePhoneNumber(activePhoneNumber);
      validateOutstandingAmount(outstandingAmount);

      if ( resignLetter.length > 0 &&
           employeeId &&
           employeeName && 
           employeeNik && 
           employeeLastDay && 
           employeeLastIncome && 
           paymentDate && 
           latestAddress && 
           activePhoneNumber && 
           outstandingAmount
      ) {
        setIsDisable(true);
        let fileReader = new FileReader();
        fileReader.onloadend = () => {
          let params = {
            base64: [fileReader.result],
            email: vnd.email
          };

          upload.uploadFileNew({ params : params }).then(res => {
              if (res.code === 200) {
                saveResignEmployee(res.data[0]);
              } else {
                message.error(res.data.message);
              }
              setIsDisable(false);
            })
        };
        fileReader.readAsDataURL(resignLetter[0]);
      }

    }

    function updateSatus(params){
      employee
      .postEmployeeStatus({
        id: employeeId,
        params: params
      })
      .then(res => {
        if (res.status === 200) {
          showModalSuccess(true);
        } else {
          message.error(res.errors[0].userMessage);
        }
      });
    }

    function saveResignEmployee(file){
      let params = {
        resign_letter_link: file,
        employee_id: Number(employeeId),
        last_working_date: employeeLastDay,
        total_last_income: employeeLastIncome,
        payment_date: paymentDate,
        latest_address: latestAddress,
        active_phone_number: activePhoneNumber,
      };
    
      employee
        .postResignedEmployees({
          params: params
        })
        .then(res => {
          if (res.status === 201) {
            showModal(false);
            updateSatus({status: "pending"});
          } else {
            message.error(res.message[flag]);
          }
        });    
    }

    const handlerResignedEmployeeConfirm = () => {
      handleSaveResignedEmployees();
    }
  
    const handleResignedEmployeeCancel = () => {
      showModal(false);
    };

    return (
      <Modal
        title={t("employee:title.resignedRequest")}
        closable={false}
        visible={visible}
        width={width}
        footer={[
          <Button key="back" onClick={handleResignedEmployeeCancel}>
            Cancel
          </Button>,
          <Button 
          key="submit" 
          type="primary" 
          disabled={isDisable}
          onClick={handlerResignedEmployeeConfirm}>
            {t("employee:button.confirm")}
          </Button>,
        ]}
      >
          <Form>
            <Row>
              <Col md={12} xs={24} className="px-3">
                <Form.Item>
                  <span className="input-label">
                    {t("employee:label.resignLetter")}
                  </span>
                  <div className="d-flex">
                  <Dropzone 
                    onDrop={handleResignLetterChange}
                    accept= ".pdf"
                    multiple={false}
                    >
                      {({getRootProps, getInputProps}) => (
                      <section className="dropzone">
                        <div {...getRootProps()}>
                          <input {...getInputProps()} />
                            <Button key="choose" type="default" >
                              {t("employee:button.chooseFile")}
                            </Button>                        
                        </div>
                      </section>
                    )}
                  </Dropzone>
                  
                    <span className="pl-2 align-self-center">
                      { resignLetter.length > 0 ? (
                        resignLetter[0].name
                      ) :'.pdf' }
                    </span>
                  </div>
                  {mandatoryResignLetter && (
                    <small className="error-validation">
                      {t("employee:text:requiredField")}
                    </small>
                  )}                
                </Form.Item>

                <Form.Item validateStatus={mandatoryEmployeeName} >
                  <span className="input-label">
                    {t("employee:label.employeeName")}
                  </span>
                  <Input
                    type="text"
                    value={employeeName}
                    id="employeeName"
                  />
                  {mandatoryEmployeeName && (
                    <small className="error-validation">
                      {t("employee:text:requiredField")}
                    </small>
                  )}
                </Form.Item>

                <Form.Item validateStatus={mandatoryEmployeeNik}>
                  <span className="input-label">
                    {t("employee:label.employeeNik")}
                  </span>
                  <Input
                    type="text"
                    value={employeeNik}
                    id="employeeNik"
                  />
                  {mandatoryEmployeeNik && (
                    <small className="error-validation">
                      {t("employee:text:requiredField")}
                    </small>
                  )}
                </Form.Item>                                                

                <Form.Item validateStatus={mandatoryEmployeeLastDay}>
                  <span className="input-label">
                    {t("employee:label.employeeLastDay")}
                  </span>
                  <DatePicker
                    className="w-100"
                    id="employeeLastDay"
                    allowClear={false}
                    onChange={handleEmployeeLastDayChange}
                  />
                  {mandatoryEmployeeLastDay && (
                    <small className="error-validation">
                      {t("employee:text:requiredField")}
                    </small>
                  )}      
                </Form.Item>                                                

                <Form.Item validateStatus={mandatoryEmployeeLastIncome}>
                  <span className="input-label">
                    {t("employee:label.employeeLastIncome")}
                  </span>
                  <InputNumber
                    type="text"
                    className="w-100"
                    value={employeeLastIncome}
                    id="employeeLastIncome"
                    formatter={value => `${(value === '' || value === null) ? '' : currencyNoPrefix(value)}`}
                    onChange={handleEmployeeLastIncomeChange}
                  />
                  {mandatoryEmployeeLastIncome && (
                    <small className="error-validation">
                      {t("employee:text:requiredField")}
                    </small>
                  )}                      
                </Form.Item>                                                                                                
              </Col>

              <Col md={12} xs={24} className="px-3">
                <Form.Item validateStatus={mandatoryPaymentDate}>
                  <span className="input-label">
                    {t("employee:label.paymentDate")}
                  </span>
                  <DatePicker
                    className="w-100"
                    id="paymentDate"
                    allowClear={false}
                    onChange={handlePaymentDateChange}
                  />                       
                  {mandatoryPaymentDate && (
                    <small className="error-validation">
                      {t("employee:text:requiredField")}
                    </small>
                  )}                                      
                </Form.Item>

                <Form.Item validateStatus={mandatoryOutstandingAmount} >
                  <span className="input-label">
                    {t("employee:label.outstandingAmount")}
                  </span>
                  <Input
                    type="text"
                    className="w-100"
                    value={currencyNoPrefix(outstandingAmount)}
                    id="outstandingAmount"
                  />
                  {mandatoryOutstandingAmount && (
                    <small className="error-validation">
                      {t("employee:text:requiredField")}
                    </small>
                  )}
                </Form.Item>

                <Form.Item validateStatus={mandatoryActivePhoneNumber}>
                  <span className="input-label">
                    {t("employee:label.activePhoneNumber")}
                  </span>
                  <Input
                    type="text"
                    value={activePhoneNumber}
                    id="activePhoneNumber"
                    onChange={handleActivePhoneNumberChange}
                  />
                  {mandatoryActivePhoneNumber && (
                    <small className="error-validation">
                      {t("employee:text:requiredField")}
                    </small>
                  )}                      
                </Form.Item>

                <Form.Item validateStatus={mandatoryLatestAddress}>
                  <span className="input-label">
                    {t("employee:label.latestAddress")}
                  </span>
                  <Input.TextArea
                    rows={3}
                    value={latestAddress}
                    onChange={handleLatestAddressChange}
                  />
                  {mandatoryLatestAddress && (
                    <small className="error-validation">
                      {t("employee:text:requiredField")}
                    </small>
                  )}                      
                </Form.Item>                                                                        
              </Col>                    
            </Row>
          </Form>
      </Modal>
    );
  }

  export default ResignedEmployeeModal;