import React, { useState, useEffect } from "react";
import { Card, Table, Pagination, message, Input, Tag, Button , Row, Col } from "antd";
import { DownloadOutlined } from '@ant-design/icons';
import { useTranslation } from "react-i18next";
import { Section } from "./style";
import invoice from "../../repository/Invoice";
import partner from "../../repository/Partner";
import history from "../../routers/history";
import { currencyNoPrefix } from "../../library/currencyFormatter";
import { dateLocaleStringFormat } from "../../library/dateTimeFormatter";
// import { isPreventDownloadDate } from "../../library/partnerPaydateHelper";
import MenuList from "../../components/Menu/MenuList";
import { getFileNameFromUrl } from "../../library/fileSaverHelper";
import { saveAs } from "file-saver";
import InvoiceDownloadModal from "../../components/Modal/InvoiceDownloadModal";

function InvoiceListContent(props) {
  const { t } = useTranslation();
  const [dataInvoice, setDataInvoice] = useState([]);
  const [totalInvoice, setTotalInvoice] = useState("");
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState(0);
  const [filters, setFilters] = useState({});
  const dataVendor = JSON.parse(window.localStorage.getItem("vnd"))||"";  
  const lang = window.localStorage.getItem("language") || "en";
  const [limit,setLimit] =  useState(10);
  const [loading, setLoadingDown] = useState(false);
  const [isModalNotification, setIsModalNotification] = useState(false);

  /** handle function download invoice & bca template */
  // function handleDownloadStatement(invoiceCode) {
  //   invoice
  //     .downloadInvoice({
  //       code: invoiceCode
  //     })
  //     .then(res => {
  //       if (res.status === 200 && res.data) {
  //         savePDF(res.data.url_download, getFileNameFromUrl(res.data.url_download));
  //       } else {
  //         message.error(res.message[lang]);
  //       }
  //     });

  //   setTimeout(function(){ 
  //     downloadBcaTemplate();
  //   }, 600);
  // }

  function handleDownloadReport(status, inv) {
    setLoadingDown(true);
    if (status !== 'upcoming') {
      let params = {invoice: inv } 
      partner
        .downloadLoanReportByInvoice(params)
        .then(res => {
          if (res.status === 200 && res.data) {
            saveAs(res.data.url_download, getFileNameFromUrl(res.data.url_download));
          } else {
            message.error(res.message[lang]);
          }
          setLoadingDown(false);
  
        });
    } else {
      setLoadingDown(false);
      setIsModalNotification(true);
    }
  }

  // function downloadBcaTemplate() {
  //   partner
  //     .getBcaTemplate({})
  //     .then(res => {
  //       if (res.status === 200 && res.data) {
  //         saveAs(res.data.url_download, getFileNameFromUrl(res.data.url_download));
  //       } else {
  //         message.error(res.message[lang])
  //       }
  //     });
  // }

  function getStatusClass(status) {
    switch (status) {
      case "paid":
        return "success";
      case "due":
        return "error";
      default:
        return "processing";
    }
  }

  function handleFilter(dataIndex, { target }) {
    let term = target.value;
    let terms = filters;
    // append search term to array of filter
    if (term.length > 1) {
      terms[target.name] = "&" + target.name + "=" + term;
      setFilters(terms);
      setPage(0);
      setSearch(search + 1);
      fetchInvoice();
    }
    // delete search term from array of filter if no search term
    if (term.length === 0) {
      let key = target.name;
      delete terms[key];
      setFilters(terms);
      if (search > 0) {        
        setSearch(search - 1);
      }
    }
  }

  // function getDisableDownload(data) {
  //   let isEnableDownload = isPreventDownloadDate(dataPartner,data);
  //   return isEnableDownload;
  // }

  function handleChangePage(value) {
    setPage(value);
    invoice
      .getInvoiceList({
        limit: limit,
        page: value,
        filter: filters
      })
      .then(res => {
        if (res.status === 200 && res.data) {
          let num = (value-1) * limit;
          const invoices = res.data.map(item => ({
            key: res.data.indexOf(item)+1,
            number: res.data.indexOf(item) + num + 1,
            invoice: item.invoice_code,
            repayable: currencyNoPrefix(item.total_repayable),
            borrower: item.number_of_borrowers,
            deadline: dateLocaleStringFormat(item.invoice_due_date),
            status: item.invoice_status,
            statusIn: (
              <Tag color={getStatusClass(item.invoice_status)}>{item.invoice_status}</Tag>
            )
          }));
          setDataInvoice(invoices);
          setTotalInvoice(res.meta.total_item);


        }
      });
  }

  const InvoiceHead = [
    { 
      title: t("employee:label.no"), 
      dataIndex: 'number', 
      key: 'number',
      width: 70,
      fixed: 'left'
    },
    { 
      title: t("employee:label.invoice"), 
      dataIndex: 'invoice', 
      key: 'invoice',
      width: 150,
      fixed: 'left'  
    },
    { 
      title: t("employee:label.totalRepayable"), 
      dataIndex: 'repayable', 
      key: 'repayable',
      width: 150 
    },
    { 
      title: t("employee:label.numberOfBorrower"), 
      dataIndex: 'borrower', 
      key: 'borrower',
      width: 180 
    },
    { 
      title: t("employee:label.deadline"), 
      dataIndex: 'deadline', 
      key: 'deadline' ,
      width: 150
    },
    // { 
    //   title: t("employee:label.status"), 
    //   dataIndex: 'statusIn', 
    //   key: 'statusIn' 
    // },
    { 
      title: "Action", 
      dataIndex: "action", 
      key: "action", 
      width: 400,
      fixed: 'right',
      render: (text, record) => (
        record.key !== 0 ? (
          <div className="btn-table">
            <Row>
              <Col span={5}>

                  <Button 
                    size="small"
                    type="default"
                    onClick={() => history.push({
                      pathname: `/list/invoice/detail/${record.key}/${record.invoice}`,
                      state: {
                        status: record.status
                    }
                    })}
                  >
                    {t("employee:button.details")}
                  </Button>
                </Col>
                <Col span={5}>
                  <Tag className={"mt-1"} color={getStatusClass(record.status)}>{record.status}</Tag>
                </Col>
                {/* <Col span={7}>
                  <Button
                    size="small"
                    type="default"
                    className={`success ${getDisableDownload(record) ? 'disable' : ''}`}
                    onClick={() => !getDisableDownload(record) 
                      && handleDownloadStatement(record.invoice)}
                      >
                    <DownloadOutlined /> {t("employee:button.downloadStatement")}
                  </Button>
                </Col> */}
                <Col span={7}>
                  <Button
                    size="small"
                    type="default"
                    id="download_on_list"
                    className={record.status === 'upcoming' ? 'greyWarning' : 'primary'}
                    onClick={() => handleDownloadReport(record.status, record.invoice)}
                    loading={loading}
                    disabled={loading}
                    >
                    <DownloadOutlined /> {t("employee:button.report")}
                  </Button>
              </Col>
            </Row>
          </div>
        ) : ""
      )
    }
  ];
  const fetchInvoice = () =>{
        invoice
        .getInvoiceList({
          page: page,
          limit: limit,
          filter: filters
        })
        .then(res => {
          if (res.status === 200 && res.data) {
            const invoices = res.data.map(item => ({
              key: item.invoice_id,
              number: res.data.indexOf(item)+1,
              invoice: item.invoice_code,
              repayable: currencyNoPrefix(item.total_repayable),
              borrower: item.number_of_borrowers,
              deadline: dateLocaleStringFormat(item.invoice_due_date),
              status: item.invoice_status,
              statusIn: (
                <Tag color={getStatusClass(item.invoice_status)}>{item.invoice_status}</Tag>
              )
            }));
            setDataInvoice(invoices); 
            setTotalInvoice(res.meta.total_item);

          }
          if(res.meta.total_item===0){
            setDataInvoice([]);
          }
      })
  }

  useEffect(() => {
    let unmounted = false;
    invoice
      .getInvoiceList({
        page: page,
        limit: limit,
        filter: filters
      })
      .then(res => {
        if (!unmounted && res.status === 200 && res.data) {
          const invoices = res.data.map(item => ({
            key: item.invoice_id,
            number: res.data.indexOf(item)+1,
            invoice: item.invoice_code,
            repayable: currencyNoPrefix(item.total_repayable),
            borrower: item.number_of_borrowers,
            deadline: dateLocaleStringFormat(item.invoice_due_date),
            status: item.invoice_status,
            statusIn: (
              <Tag color={getStatusClass(item.invoice_status)}>{item.invoice_status}</Tag>
            )
          }));
          setDataInvoice(invoices); 
          setTotalInvoice(res.meta.total_item);

        }
        
      });

    return () => { 
      unmounted = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search,limit,page]);

  // append search field within the table on first row
  if (totalInvoice !== "") {
    dataInvoice.unshift({
      key: 0,
      number: "",
      invoice: <Input type="text" name="invoice_code" onChange={e => handleFilter("invoice", e)} />,
      repayable: "",
      borrower: "",
      deadline: "",
      status: ""
    });
  }
  
  const onShowSizeChange = (current, pageSize) => {
    
    setLimit(pageSize);
    setPage(current);
    fetchInvoice();

  }

  return (
    <Section>
      <div className="kwEmployee">
        <h2>{t("employee:title.invoiceList")}</h2>
        <MenuList activeKey="invoice" />
        <Card>
          <h3>{dataVendor.name}</h3>
          <Table 
            columns={InvoiceHead}
            dataSource={totalInvoice !== "" ? dataInvoice: []}
            pagination={false}
            scroll={{ x: 'calc(700px + 50%)' }}
          />
          <Row>
              <Col span={24}>
                  <div className="mt-4">
                  {/* <Input.Group compact>
                    Show 
                  <InputNumber min={1} className="mx-3" max={50} defaultValue={limit} onChange={onChangeMaxList} />
                     <label htmlFor="">Per page {limit} {page} {totalInvoice}</label>
                  </Input.Group> */}
                   <div className="pagination">

                   <Pagination
                    defaultPageSize={limit}  
                    defaultCurrent={page} 
                    current={page}
                    total={totalInvoice}
                    onChange={handleChangePage}
                    showSizeChanger
                    onShowSizeChange={onShowSizeChange}
                    pageSizeOptions={[5,10, 20, 50, 100]}
                    />
                  </div>
                </div>

              </Col>
          
              {/* <Col span={12}> */}
                {/* <div className="pagination">
                  {totalInvoice && (
                    <Pagination
                    defaultPageSize={limit}  
                    defaultCurrent={page} 
                    current={page}
                    total={totalInvoice}
                    onChange={handleChangePage}
                    // showSizeChanger={false}
                    />
                  )}
                </div> */}
              {/* </Col> */}
          </Row>
        </Card>
      </div>

      <InvoiceDownloadModal
          visible={isModalNotification}
          lang={t}
          setIsModalNotification={setIsModalNotification}
      />

    </Section>
  );
};

export default InvoiceListContent;
