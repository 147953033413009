import React, { useState, useEffect } from "react";
import { Table, Pagination, Card, Input, Button, message } from "antd";
import { DownloadOutlined } from '@ant-design/icons';
import { useTranslation } from "react-i18next";
import { Section } from "./style";
import invoice from "../../repository/Invoice";
import partner from "../../repository/Partner";
import history from "../../routers/history";
import { currencyNoPrefix } from "../../library/currencyFormatter";
// import { isPreventDownloadDate2 } from "../../library/partnerPaydateHelper";
import { getFileNameFromUrl } from "../../library/fileSaverHelper";
import { saveAs } from "file-saver";
import moment from "moment";
import { useLocation } from "react-router-dom";
import InvoiceDownloadModal from "../../components/Modal/InvoiceDownloadModal";

function InvoiceDetailContent(props) {
  const { t } = useTranslation();
  const [dataInvoice, setDataInvoice] = useState([]);
  const [totalInvoice, setTotalInvoice] = useState("");
  // const [invoiceCode, setInvoiceCode] = useState([]);
  const [invoiceDate, setInvoiceDate] = useState([]);
  // const [disableDownload, setDisableDownload] = useState(true);
  const [loading2, setLoading2] = useState(false);
  const [metaData, setMetaData] = useState({});
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState(0);
  const [filters, setFilters] = useState({});
  const [limit ,setLimit] = useState(10);
  const id = props.match.params.id;
  // const code = props.match.params.code;  
  const dataVendor = JSON.parse(window.localStorage.getItem("vnd"));
  const lang = window.localStorage.getItem("language") || "en";
  const location = useLocation();
  const {status} = location.state;
  const [isModalNotification, setIsModalNotification] = useState(false);

  /** handle data filtering by input text */
  function handleFilter(dataIndex, { target }) {
    let term = target.value;
    let terms = filters;
    if (term.length > 1) {
      terms[target.name] = "&sort_by=l.applied_at&sort_type=ASC&" + target.name + "=" + term;
      setFilters(terms);
      setPage(1);
      setSearch(search + 1);
      fetcInvoiceDetail();
    }
    if (term.length === 0) {
      let key = target.name;
      delete terms[key];
      setFilters(terms);
      if (search > 0) {        
        setSearch(search - 1);
      }
    }
  }

  function handleDownloadReport() {
    setLoading2(true);
    if (status !== 'upcoming') {
      partner
        .getCurrentLoanReport({})
        .then(res => {
          if (res.status === 200 && res.data) {
            saveAs(res.data.url_download, getFileNameFromUrl(res.data.url_download));
          } else {
            message.error(res.message[lang])
          }
          setLoading2(false);
        });
    } else {
      setLoading2(false);
      setIsModalNotification(true);
    }
  }

  /** handling event change page of data table invoice detail */
  function handleChangePage(value) {
    setPage(value);
    fetcInvoiceDetail();
  }
  function humanize(str) {
    if(str){
      var i, frags = str.split('_');
      for (i=0; i<frags.length; i++) {
        frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
      }
      return frags.join(' ');
    }else{
      return "";
    }
  }
  const invoiceHead = [
    { 
      title: t("employee:label.no"), 
      dataIndex: 'number', 
      key: 'number',      
      width: 70,
      fixed: 'left'
    },
    { 
      title: t("employee:label.invoice"), 
      dataIndex: 'invoice', 
      key: 'invoice',
      width: 150,
      fixed: 'left' 
    },
    { 
      title: t("employee:label.applicationDate"), 
      dataIndex: 'applicationDate', 
      key: 'applicationDate',
      width: 150
     
    },
    { 
      title: t("employee:label.employeeName"), 
      dataIndex: 'name', 
      key: 'name',
      width: 180 
    },
    { 
      title: t("employee:label.department"), 
      dataIndex: 'department', 
      key: 'department',
      width: 150 
    },
    { 
      title: t("employee:label.totalDisbursed"), 
      dataIndex: 'disbursed', 
      key: 'disbursed',
      width: 150 
    },
    { 
      title: t("employee:label.totalRepayable"), 
      dataIndex: 'repayable', 
      key: 'repayable',
      width: 150 
    },
    { 
      title: t("employee:label.dueDate"), 
      dataIndex: 'dueDate', 
      key: 'dueDate',
      width: 150 
    },
    { 
      title: t("employee:label.loanType"), 
      dataIndex: 'loanType',
      width: 150,
      key: 'loanType',
      render: (text, record ) => (
        
        record.key !== 0 ? (
        <span>{humanize(record.loanType)}</span>
        ) : ""
      )
    },
    { 
      title: t("employee:label.installmentNo"), 
      dataIndex: 'installment', 
      width: 200,
      key: 'installmentNo',
      render: (text, record ,installment) => (
        record.key !== 0 ? (
        <span>
          {record.loanType!=="salary_advance" ? 
            record.installmentNo||0 +"/"+ record.tenure
          :""}
        </span>
        ) : ""
      ) 
    },
  ];
  
  const dateToString = (dates)=>{
    // let dateobj = new Date(dates);
    // let bre = dateobj.toUTCString();
    let momDate = moment.utc(dates).format('ll');   // Apr 14, 2021
    return momDate;
  }
  
  
  const fetcInvoiceDetail = () =>{
    invoice
      .getInvoiceDetail({
        id: id,
        limit: limit,
        page: page,
        filter: filters
      })
      .then(res => {
        if (res.status === 200 && res.data) {
          
          const invoices = res.data.map(
            item => ({
              key: res.data.indexOf(item)+1,
              number: res.data.indexOf(item)+1,
              invoice: item.invoice_code,
              repayable: currencyNoPrefix(item.total_repayable),
              disbursed: currencyNoPrefix(item.total_disbursed),
              department: item.department,
              name: item.employee_name,
              applicationDate: dateToString(item.application_date),
              dueDate: dateToString(item.due_date),
              loanType: item.loan_type,
              installmentNo: item.installment_no,
              tenure: item.tenure,
            })
          );
          let dateInvoice = invoices[0].applicationDate.split(" ");
          // setDisableDownload(isPreventDownloadDate2(dataVendor,dateInvoice));
          // setInvoiceCode(invoices[0].invoice);
          setInvoiceDate(dateInvoice[0] + " " + dateInvoice[2]);
          setDataInvoice(invoices); 
          setMetaData(res.meta);
          setTotalInvoice(res.meta.total_item);
        }
        if(res.data == null){
          setDataInvoice([]);
        }
      });
  }


  // useEffect(() => {
  //   let unmounted = false;
  //   invoice
  //     .getInvoiceList({
  //       id: id,
  //       limit: limit,
  //       page: page,
  //       filter: { invoice_code : `&invoice_code=${code}`}
  //     })
  //     .then(res => {
  //       if (!unmounted && res.status === 200 && res.data) {
  //         let details = res.data[0];
  //         let dateInvoice = details.invoice_due_date.split("T");
  //         setDisableDownload(
  //           isPreventDownloadDate2(dataVendor,dateInvoice[0],details.invoice_status)
  //         );
  //       }
  //     });

  //   return () => { 
  //     unmounted = true;
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [filters]);

  const onShowSizeChange = (current, pageSize) => {
    
    setLimit(pageSize);
    setPage(current);
    fetcInvoiceDetail();

  }


  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      fetcInvoiceDetail();
    }

    return () => { 
      unmounted = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search,page,limit]);
  
  if (metaData && metaData.total_item > 0) {
    dataInvoice.unshift({
      key: 0,
      number: "",
      invoice: <Input type="text" name="invoice_code" onChange={e => handleFilter("invoice", e)} />,
      repayable: "",
      disbursed: "",
      department: "",
      name: <Input type="text" name="employee_name" onChange={e => handleFilter("name", e)} />,
      applicationDate: <Input type="date" name="aplication_date" onChange={e => handleFilter("application_date", e)} />,
      dueDate: "",
      loanType:"",
      tenure: "",
      installmentNo: "",

    });
  }

  return (
    <Section>
      <Button 
        type="primary" 
        onClick={() => history.push("/list/invoice")}
        danger
      >
        &larr; {t("employee:button.back")}
      </Button>
      <div className="kwInvoice">
        <h2>
          {t("employee:title.invoiceDetail")} - {invoiceDate}
        </h2>
        {/* <div className="kwInvoice__top-description">
          <span>{t("employee:label.totalPaid")}: Rp {metaData.total_paid}</span>
          <br/>
          <span>{t("employee:label.remainingDue")}: Rp {metaData.remaining_due}</span>
          <br/>
          <span>{t("employee:label.vaBalance")}: Rp {metaData.va_balance}</span>
        </div> */}

        <Card>
          <h3>{dataVendor.name}</h3>
          <div className="kwInvoice__top-button">
            {/* <Button 
              type="default"
              onClick={handleDownloadStatement}
              disabled={disableDownload || loading1}
              loading={loading1}
              className="primary"
            >
              <DownloadOutlined /> {t("employee:button.salaryAdStatement")}
            </Button> */}
            <Button 
              type="default"
              onClick={handleDownloadReport}
              disabled={loading2}
              loading={loading2}
              className={status === 'upcoming' ? 'greyWarning' : 'success'}
            >
              <DownloadOutlined /> {t("employee:button.report")}
            </Button>
          </div>
          <Table
            columns={invoiceHead}
            pagination={false}
            scroll={{ x: 'calc(700px + 50%)' }}
            dataSource={metaData && metaData.total_item ?
              dataInvoice : []}
          />
          <div className="pagination">
            {totalInvoice && (
              <Pagination
              defaultPageSize={limit}  
              defaultCurrent={page} 
                current={page}
                total={totalInvoice}
                onChange={handleChangePage}
                showSizeChanger
                onShowSizeChange={onShowSizeChange}
              />
            )}
          </div>
        </Card>
      </div>

      <InvoiceDownloadModal
          visible={isModalNotification}
          lang={t}
          setIsModalNotification={setIsModalNotification}
      />
      
    </Section>
  );
};

export default InvoiceDetailContent;
